import React, { Component } from "react";
import { FiPower , FiZap , FiUser , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiUser />,
        title: 'UX Strategy',
        description: 'I work with teams across the org to improve their structure, processes and cross-functional collaboration and unlock the potential of lean experiences that prioritise research and continuous, learning iterations.'
    },
    {
        icon: <FiZap />,
        title: 'Mentorship & Leadership',
        description: "I am passionate about working closely with individuals to help achieve their personal growth and success. I also actively coach and mentor product designers around the globe."
    },
    {
        icon: <FiPower />,
        title: 'Product Design & Research',
        description: 'I enjoy bringing products and delightful experiences to life with u̶s̶e̶r̶s̶ humans in mind.'
    },
    {
        icon: <FiMonitor />,
        title: 'Web & App Development',
        description: 'Medium to strong development skills and attention to details– I love collaborating with engineers to ensure beautiful (not only decent) results.'
        // description: 'With medium to strong development skills in most of the stack and attention to details, I love to collaborate with engineers to ensure beautiful (not only decent) results.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'How I can help you and your team',
        description = "I'm very passionate about my strengths, but what you see here is not all of them. Feel free to ask if you need more!";
        return(
            <React.Fragment>
                <div id="" className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="subtitle">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/contact"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    {/* <a href="/service-details"> */}
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    {/* </a> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
