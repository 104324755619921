import React, { Component } from "react";
import PageHelmet from "../../../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Breadcrumb from "../../../elements/common/Breadcrumb"
import Footer from "../../../component/footer/Footer";
import HeaderEmpty from "../../../component/header/HeaderEmpty";

class FAPortfolioDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)

    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        // const { title , parent} = this.props;
        return(
            <React.Fragment>
                <div className="active-dark"> 
                <PageHelmet pageTitle='Portfolio Details' />

                <HeaderEmpty headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                </div>
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--100 pb--100 bg_image bg_image--wi"  data-black-overlay="7">
                    <Breadcrumb title={'Worse Ideas'} subtitle={'Ideas Database - Share your ideas to change the world.'}  />
                </div>
                {/* End Breadcrump Area */}


                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3>Matteo Giuzzi</h3>
                                        <p className="project-role">Project role:</p>
                                        <h2>Founder</h2>
                                        <p className="subtitle">2020</p>
                                        <p>	Our mission is to change the old paradigm where ideas are kept in a drawer, or in the works, until they’re polished perfectly instead of being shared with the world with the fear of them being rejected or that someone could copy or steal them.<br></br>We think everyone should feel they can make a difference in and for the world and not be ashamed of it.</p>

                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Industry</span>
                                                <h4>Nonprofits, Philanthropic Project</h4>
                                            </div>

                                            <div className="port-view">
                                                <span>Platforms</span>
                                                <h4>Web</h4>
                                            </div>
                                        </div>

                                        <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
                                        </div>
                                    </div>
                                    <div className="portfolio-thumb-inner">
                                        <div className="thumb mb--30">
                                            <img className="wi-off" src="/assets/images/portfolio/WorseIdeas/WorseIdeas_01.jpg"
                                            onMouseOver={e => (e.currentTarget.src = "/assets/images/portfolio/WorseIdeas/WorseIdeas_02.jpg")}
                                            onMouseOut={e => (e.currentTarget.src = "/assets/images/portfolio/WorseIdeas/WorseIdeas_01.jpg")}  alt="WorseIdeas logo"/>                                           
                                        </div>

                                        <div className="thumb mb--30">
                                            <img src="/assets/images/portfolio/WorseIdeas/WorseIdeas_03.jpg" alt="WorseIdeas mockup"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="view-more-btn mt--60 text-center">
                                                <a className="rn-button-style--2 btn-solid" href="https://worseideas.s3.eu-west-1.amazonaws.com/index.html" target="_blank" rel="noopener noreferrer"><span>Check out Worse Ideas' Demo Website</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Related Work</span>
                                    <h2>Explore Other Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="https://www.mediboost.it" target="_blank" rel="noopener noreferrer">
                                            <img src="/assets/images/portfolio/Mediboost/Mediboost_01.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="https://www.mediboost.it" target="_blank" rel="noopener noreferrer">Mediboost</a></h4>
                                        <span className="category">Role: Co-Founder</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/portfolio-details-dogbuddy">
                                            <img src="/assets/images/portfolio/DogBuddy/DogBuddy_01.jpg" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/portfolio-details-dogbuddy">DogBuddy</a></h4>
                                        <span className="category">Role: Lead Product Designer</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="view-more-btn mt--60 text-center">
                                    <a className="rn-button-style--1 rn-btn" href={`${process.env.PUBLIC_URL}/#portfolio`}><span>View All Project</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default FAPortfolioDetails;
