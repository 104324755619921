import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Experience",
        tab2 = "Main skills",
        tab3 = "Indie Projects",
        tab4 = "Places I've lived in";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area mg-tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                                <li>
                                                   <a href="https://workrooms.com" target="_blank"><img className="mr--10" src="/assets/images/about/metafb_logo.png" alt="Meta logo" width="30"/>Meta (Horizon Workrooms) <span>- Lead Product Designer</span></a> 2022 - Today
                                               </li>
                                               <li>
                                                   <a href="https://rover.com" target="_blank"><img className="mr--10" src="/assets/images/about/rover_logo.png" alt="Rover logo" width="30"/>Rover.com <span>- Lead Product Designer & Design Manager</span></a> 2018 - 2022
                                               </li>
                                               <li>
                                                   <a href="https://dogbuddy.com" target="_blank"><img className="mr--10" src="/assets/images/about/dogbuddy_logo.png" alt="DogBuddy logo" width="30"/>DogBuddy (acquired by Rover) <span>- Lead Product Designer</span></a> 2015 - 2018
                                               </li>
                                               <li>
                                                   <a href="" target="_blank">Various startups<span>- Product Designer</span></a> 2013-2015
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                {/* <li>
                                                    <a href="/" className="disabled-link">Mentorship and Leadership</a>
                                                    Humans want to be superheroes. I'm there every step of the way to remind them they can be.
                                                </li> */}
                                                <li>
                                                    <a href="/" className="disabled-link">Product Design <span> - UI/UX & Mentoring</span></a>
                                                    Of course.
                                                </li>
                                                <li>
                                                    <a href="/" className="disabled-link">Product Strategy & Success<span> - Product Thinking</span></a>
                                                    Setting and aligning business goals, team OKRs, long-term vision and evaluation of product iterations success.
                                                </li>
                                                <li>
                                                    <a href="/" className="disabled-link">MVPs Enthusiast <span> - Entrepreneurship</span></a>
                                                    Not the NBA ones. If I have an idea, I often end up putting out in the world a very first version of it and then take it from there.
                                                </li>
                                                <li>
                                                    <a href="/" className="disabled-link">Coding <span> - Development</span></a>
                                                    This secret passion of mine incredibly boosts collaboration with engineers and allows me to have independence when I want to bring ideas to life. 
                                                </li>
                                                <li>
                                                    <a href="/" className="disabled-link">Interaction Design <span> - Animation</span></a>
                                                    Lottie's expert and very passionate about delight.
                                                    <p>𝐼 𝑙𝑖𝑘𝑒 𝑡𝑜 𝑚𝑜𝑣𝑒 𝑖𝑡, 𝑚𝑜𝑣𝑒 𝑖𝑡.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                                <li>
                                                   <a href="https://www.ourgen.uk/" target="_blank">ourGen<span> - Peer-to-peer knowledge base of student careers information</span></a> 2021 - Today
                                               </li>
                                                <li>
                                                   <a href="https://worseideas.s3.eu-west-1.amazonaws.com/index.html" target="_blank">Worse Ideas, Ideas Database (discontinued)<span> - Share your ideas to change the world.</span></a> 2019 - 2022
                                               </li>
                                                <li>
                                                   <a href="https://mediboost.s3.eu-west-1.amazonaws.com/index.html" target="_blank">Mediboost (discontinued)<span> - Boost your medical practice's online presence.</span></a> 2019 - 2021
                                               </li>
                                               <li>
                                                   <a href="" className="disabled-link">CarePal<span> (discontinued)</span></a> 2018 - 2020
                                               </li>
                                               <li>
                                                   <a href="https://s3.amazonaws.com/www.giuzzi.com/appsdodos/index.html" target="_blank">Dodo Codes Exchange App<span> - Seamlessly Exchange Animal Crossing's dodo codes.</span></a> 2020 - 2022
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/" className="disabled-link">Barcelona<span>, Spain</span></a>2016 - Today
                                               </li>
                                               <li>
                                                   <a href="/" className="disabled-link">London<span>, England</span></a> 2013 - 2016
                                               </li>
                                               <li>
                                                   <a href="/" className="disabled-link">Milan<span>, Italy</span></a> 1990 - 2016
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;