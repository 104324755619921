import React, { Component } from "react";
import TabTwo from "../../../elements/tab/TabTwo";

class About extends Component{
    render(){
        let title = 'About',
        description = "Senior Product Designer at Meta (Reality Labs). Eating products and design for breakfast since 2013, even though sometimes I just prefer poached eggs. Also writer and coder of ideas in my free time.";
        return(
            <React.Fragment>
                    {/* Start About Area */}
                    <div className="fix">
                            <div className="about-wrapper">
                                <div className="container">
                                    <div className="row row--35 align-items-center">
                                        <div className="col-lg-5">
                                            <div className="thumbnail">
                                                <img className="w-100" src="/assets/images/about/matteo01.jpg" alt="About Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="about-inner inner">
                                                <div className="section-title">
                                                    <h2 className="title">{title}</h2>
                                                    <p className="description">{description}</p>
                                                </div>
                                                <div className="row mt--30">
                                                    <TabTwo tabStyle="tab-style--1" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    {/* End About Area */}
            </React.Fragment>
        )
    }
}
export default About;