const BlogContent = [
    {
        images: '01',
        title: ' Designers need codes, not keys, to unlock their career',
        category: 'Medium post',
        url: 'https://medium.com/@teo155/designers-need-codes-not-keys-to-unlock-their-career-94363691f463'
    },
    {
        images: '02',
        title: 'How to pick the best colours for your palette (part 1)',
        category: 'Medium post',
        url: 'https://medium.com/@teo155/how-to-pick-the-best-colours-for-your-palette-part-1-828b7ce16f8c'
    },

    {
        images: '03',
        title: 'A storyboard is worth a thousand words',
        category: 'Medium post',
        url: 'https://medium.com/@teo155/a-storyboard-is-worth-a-thousand-words-7f7d45247d3f'
    }
]

export default BlogContent;